import { Component } from 'react';
import emailjs from '@emailjs/browser';

const member = {
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: '',
    email: '',
    phone: '',
    country: 'United States',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    ownershipPercentage: 0,
    ssn: null,
}

class CorporateForm extends Component {

    constructor(props) {
        super(props);

        let contactInfo = {
            firstName: '',
            middleName: '',
            lastName: '',
            suffix: '',
            email: '',
            phone: '',
            country: 'United States',
            address: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
        }

        let memberInfo = [];
        memberInfo.push(Object.create(member));
        memberInfo[0].country = 'United States';

        let agentInfo = {
            companyName: '',
            firstName: '',
            middleName: '',
            lastName: '',
            suffix: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
        }

        let companyInfo = {
            address: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
        }

        this.state = {
            contactInfo: contactInfo,
            memberInfo: memberInfo,
            companyInfo: companyInfo,
            companyName1: '',
            companyName2: '',
            companyName3: '',
            entityType: 'LLC',
            principleActivity: 'Transportation & Warehousing',
            otherPrincipleActivity: '',
            listed: 'Included',
            formAcknowledged: false,
            relatedOffers: false,
            isSubmitDisabled: true,
            requireRegisteredAgent: false,
            agentInfo: agentInfo,
        }
    }

    handleContactChange(event) {
        let tempContactInfo = this.state.contactInfo;

        if (event.target.id === 'contact-first-name') {
            tempContactInfo.firstName = event.target.value;
        }

        else if (event.target.id === 'contact-middle-name') {
            tempContactInfo.middleName = event.target.value;
        }

        else if (event.target.id === 'contact-last-name') {
            tempContactInfo.lastName = event.target.value;
        }

        else if (event.target.id === 'contact-suffix') {
            tempContactInfo.suffix = event.target.value;
        }

        else if (event.target.id === 'contact-email') {
            tempContactInfo.email = event.target.value;
        }

        else if (event.target.id === 'contact-phone-number') {
            tempContactInfo.phone = event.target.value;
        }

        else if (event.target.id === 'contact-country') {
            tempContactInfo.country = event.target.value;
        }

        else if (event.target.id === 'contact-address') {
            tempContactInfo.address = event.target.value;
        }

        else if (event.target.id === 'contact-address-2') {
            tempContactInfo.address2 = event.target.value;
        }

        else if (event.target.id === 'contact-city') {
            tempContactInfo.city = event.target.value;
        }

        else if (event.target.id === 'contact-state') {
            tempContactInfo.state = event.target.value;
        }

        else if (event.target.id === 'contact-zip') {
            tempContactInfo.zip = event.target.value;
        }

        this.setState({ contactInfo: tempContactInfo });

    }

    handleMemberChange(event, i) {
        let tempMemberInfo = this.state.memberInfo;

        if (event.target.id === `member-first-name-${i}`) {
            tempMemberInfo[i].firstName = event.target.value;
        }

        else if (event.target.id === `member-middle-name-${i}`) {
            tempMemberInfo[i].middleName = event.target.value;
        }

        else if (event.target.id === `member-last-name-${i}`) {
            tempMemberInfo[i].lastName = event.target.value;
        }

        else if (event.target.id === `member-suffix-${i}`) {
            tempMemberInfo[i].suffix = event.target.value;
        }

        else if (event.target.id === `member-email-${i}`) {
            tempMemberInfo[i].email = event.target.value;
        }

        else if (event.target.id === `member-phone-number-${i}`) {
            tempMemberInfo[i].phone = event.target.value;
        }

        else if (event.target.id === `member-country-${i}`) {
            tempMemberInfo[i].country = event.target.value;
        }

        else if (event.target.id === `member-address-${i}`) {
            tempMemberInfo[i].address = event.target.value;
        }

        else if (event.target.id === `member-address-2-${i}`) {
            tempMemberInfo[i].address2 = event.target.value;
        }

        else if (event.target.id === `member-city-${i}`) {
            tempMemberInfo[i].city = event.target.value;
        }

        else if (event.target.id === `member-state-${i}`) {
            tempMemberInfo[i].state = event.target.value;
        }

        else if (event.target.id === `member-zip-${i}`) {
            tempMemberInfo[i].zip = event.target.value;
        }

        else if (event.target.id === `member-middle-name-${i}`) {
            tempMemberInfo[i].middleName = event.target.value;
        }

        else if (event.target.id === `member-ownership-percentage-${i}`) {
            tempMemberInfo[i].ownershipPercentage = event.target.value;
            this.submitDisabled();
        }

        else if (event.target.id === `member-ssn-${i}`) {
            tempMemberInfo[i].ssn = event.target.value;
        }

        this.setState({ memberInfo: tempMemberInfo });
    }

    handleCompanyChange(event) {
        let tempCompanyInfo = this.state.companyInfo;

        if (event.target.id === 'company-address') {
            tempCompanyInfo.address = event.target.value;
        }

        else if (event.target.id === 'company-address-2') {
            tempCompanyInfo.address2 = event.target.value;
        }

        else if (event.target.id === 'company-city') {
            tempCompanyInfo.city = event.target.value;
        }

        else if (event.target.id === 'company-state') {
            tempCompanyInfo.state = event.target.value;
        }

        else if (event.target.id === 'company-zip') {
            tempCompanyInfo.zip = event.target.value;
        }

        this.setState({ companyInfo: tempCompanyInfo });
    }

    handleAgentChange(event) {
        let tempAgentInfo = this.state.agentInfo;

        if (event.target.id === 'agent-company-name') {
            tempAgentInfo.companyName = event.target.value;
        }

        else if (event.target.id === 'agent-first-name') {
            tempAgentInfo.firstName = event.target.value;
        }

        else if (event.target.id === 'agent-middle-name') {
            tempAgentInfo.middleName = event.target.value;
        }

        else if (event.target.id === 'agent-last-name') {
            tempAgentInfo.lastName = event.target.value;
        }

        else if (event.target.id === 'agent-suffix') {
            tempAgentInfo.suffix = event.target.value;
        }

        else if (event.target.id === 'agent-address') {
            tempAgentInfo.address = event.target.value;
        }

        else if (event.target.id === 'agent-address-2') {
            tempAgentInfo.address2 = event.target.value;
        }

        else if (event.target.id === 'agent-city') {
            tempAgentInfo.city = event.target.value;
        }

        else if (event.target.id === 'agent-state') {
            tempAgentInfo.state = event.target.value;
        }

        else if (event.target.id === 'agent-zip') {
            tempAgentInfo.zip = event.target.value;
        }

        this.setState({ agentInfo: tempAgentInfo });
    }

    handleRequireAgentChange(event) {
        if (event.target.value === 'Yes, Fee is $0 for first year after incorporation and $119 every year thereafter. Cancel anytime.') {
            this.setState({ requireRegisteredAgent: true });
        }

        else if (event.target.value === 'No, I will have my own Registered Agent. Name and address provided below.') {
            this.setState({ requireRegisteredAgent: false });
        }
    }

    handleCompanyNameChange(event) {
        if (event.target.id === 'company-name-1') {
            this.setState({ companyName1: event.target.value });
        }

        else if (event.target.id === 'company-name-2') {
            this.setState({ companyName2: event.target.value });
        }

        else if (event.target.id === 'company-name-3') {
            this.setState({ companyName3: event.target.value });
        }
    }

    handleEntityChange(event) {
        if (event.target.id === 'entity-type') {
            this.setState({ entityType: event.target.value });
        }

        else if (event.target.id === 'principle-activity') {
            this.setState({ principleActivity: event.target.value });
        }

        else if (event.target.id === 'other-principle-activity') {
            this.setState({ otherPrincipleActivity: event.target.value });
        }

        else if (event.target.id === 'listed') {
            this.setState({ listed: event.target.value });
        }
    }

    addMember() {
        if (this.state.memberInfo.length < 3) {
            let memberInfo = this.state.memberInfo;
            memberInfo.push(Object.create(member));
            memberInfo[memberInfo.length - 1].country = 'United States';
            this.setState({ memberInfo: memberInfo });
        } 
    }

    removeMember(i) {
        if (this.state.memberInfo.length > 1) {
            let memberInfo = this.state.memberInfo;
            memberInfo.splice(i, i);
            
            this.setState({ memberInfo: memberInfo });
        }
    }

    handleFormAcknowledgement(event) {
        this.setState({ formAcknowledged: event.target.checked }, this.submitDisabled);
    }

    handleRelatedOffers(event) {
        this.setState({ relatedOffers: event.target.checked });
    }

    submitDisabled() {
        let totalOwnershipPercentage = 0;

        for (let i = 0; i < this.state.memberInfo.length; i++) {
            totalOwnershipPercentage += parseFloat(this.state.memberInfo[i].ownershipPercentage);
        }

        if (totalOwnershipPercentage === 100 && this.state.formAcknowledged) {
            this.setState({ isSubmitDisabled: false });
        } else {
            this.setState({ isSubmitDisabled: true });
        }
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ isSubmitDisabled: true });

        let ownersMessageUser = '';
        let ownersMessageVince = '';
        let registeredAgentMessage = '';


        for (let i = 0; i < this.state.memberInfo.length; i++ ) {
            ownersMessageUser += `
                Owner ${i + 1}
                
                First Name: ${this.state.memberInfo[i].firstName}
                Middle Name: ${this.state.memberInfo[i].middleName}
                Last Name: ${this.state.memberInfo[i].lastName}
                Suffix: ${this.state.memberInfo[i].suffix}
                Email: ${this.state.memberInfo[i].email}
                Phone Number: ${this.state.memberInfo[i].phone}
                Country: ${this.state.memberInfo[i].country}
                Street Address: ${this.state.memberInfo[i].address}
                Street Address 2: ${this.state.memberInfo[i].address2}
                City: ${this.state.memberInfo[i].city}
                State: ${this.state.memberInfo[i].state}
                Zip Code: ${this.state.memberInfo[i].zip}
                % Ownership: ${this.state.memberInfo[i].ownershipPercentage}
                SSN: *** ** ${this.state.memberInfo[i].ssn.slice(-4)}
            `;

            ownersMessageVince += `
            Owner ${i + 1}
            
            First Name: ${this.state.memberInfo[i].firstName}
            Middle Name: ${this.state.memberInfo[i].middleName}
            Last Name: ${this.state.memberInfo[i].lastName}
            Suffix: ${this.state.memberInfo[i].suffix}
            Email: ${this.state.memberInfo[i].email}
            Phone Number: ${this.state.memberInfo[i].phone}
            Country: ${this.state.memberInfo[i].country}
            Street Address: ${this.state.memberInfo[i].address}
            Street Address 2: ${this.state.memberInfo[i].address2}
            City: ${this.state.memberInfo[i].city}
            State: ${this.state.memberInfo[i].state}
            Zip Code: ${this.state.memberInfo[i].zip}
            % Ownership: ${this.state.memberInfo[i].ownershipPercentage}
            SSN: ${this.state.memberInfo[i].ssn}
        `;
        }

        if (!this.state.requireRegisteredAgent) {
            registeredAgentMessage = `
                Company Name: ${this.state.agentInfo.companyName}
                First Name: ${this.state.agentInfo.firstName}
                Middle Name: ${this.state.agentInfo.middleName}
                Last Name: ${this.state.agentInfo.lastName}
                Suffix: ${this.state.agentInfo.suffix}
                Street Address: ${this.state.agentInfo.address}
                Street Address 2: ${this.state.agentInfo.address2}
                City: ${this.state.agentInfo.city}
                State: ${this.state.agentInfo.state}
                Zip Code: ${this.state.agentInfo.zip}
            `;
        }

        let messageToVince = `
            Contact Info:
            
            First Name: ${this.state.contactInfo.firstName}
            Middle Name (Optional): ${this.state.contactInfo.middleName}
            Last Name: ${this.state.contactInfo.lastName}
            Suffix: ${this.state.contactInfo.suffix}
            Email: ${this.state.contactInfo.email}
            Phone Number: ${this.state.contactInfo.phone}
            Country: ${this.state.contactInfo.country}
            Street Address: ${this.state.contactInfo.address}
            Street Address 2: ${this.state.contactInfo.address2}
            City: ${this.state.contactInfo.city}
            State: ${this.state.contactInfo.state}
            Zip Code: ${this.state.contactInfo.zip}

            Owners:
            ${ownersMessageVince}

            Company Name And Situs:

            Company Names

            Option 1: ${this.state.companyName1}
            Option 2: ${this.state.companyName2}
            Option 3: ${this.state.companyName3}

            Company Situs Details

            Street Address: ${this.state.companyInfo.address}
            Street Address 2: ${this.state.companyInfo.address2}
            City: ${this.state.companyInfo.city}
            State: ${this.state.companyInfo.state}
            Zip Code: ${this.state.companyInfo.zip}

            Entity Details:

            Entity Type: ${this.state.entityType}
            Principle Activity: ${this.state.principleActivity}
            ${this.state.principleActivity === 'Other' ? `Other: ${this.state.otherPrincipleActivity}` : ''}
            LLC Members: ${this.state.listed}

            Registered Agent

            Will we be handling your registered agent: ${this.state.requireRegisteredAgent ? 'Yes, Fee is $0 for first year after incorporation and $119 every year thereafter. Cancel anytime.' : 'No, I will have my own Registered Agent. Name and address provided below.'}
            ${registeredAgentMessage}

            ${this.state.relatedOffers ? 'You have elected to receive additional offers from MCS' : 'You have elected NOT to receive additional offers from MCS'}

            You receiving this email means that you have agreed to provide authority to Meraviglia Consulting Services (MCS), to complete state business establishment forms and acquire a Federal EIN number on your behalf.
        `;

        let messageToCustomer = `
            Contact Info:
            
            First Name: ${this.state.contactInfo.firstName}
            Middle Name (Optional): ${this.state.contactInfo.middleName}
            Last Name: ${this.state.contactInfo.lastName}
            Suffix: ${this.state.contactInfo.suffix}
            Email: ${this.state.contactInfo.email}
            Phone Number: ${this.state.contactInfo.phone}
            Country: ${this.state.contactInfo.country}
            Street Address: ${this.state.contactInfo.address}
            Street Address 2: ${this.state.contactInfo.address2}
            City: ${this.state.contactInfo.city}
            State: ${this.state.contactInfo.state}
            Zip Code: ${this.state.contactInfo.zip}

            Owners:

            ${ownersMessageUser}

            Company Name Options:

            Option 1: ${this.state.companyName1}
            Option 2: ${this.state.companyName2}
            Option 3: ${this.state.companyName3}

            Entity Details:

            Entity Type: ${this.state.entityType}
            Principle Activity: ${this.state.principleActivity}
            ${this.state.principleActivity === 'Other' ? `Other: ${this.state.otherPrincipleActivity}` : ''}
            LLC Members: ${this.state.listed}

            Registered Agent

            Will we be handling your registered agent: ${this.state.requireRegisteredAgent ? 'Yes, Fee is $0 for first year after incorporation and $119 every year thereafter. Cancel anytime.' : 'No, I will have my own Registered Agent. Name and address provided below.'}
            ${registeredAgentMessage}

            ${this.state.relatedOffers ? 'You have elected to receive additional offers from MCS' : 'You have elected NOT to receive additional offers from MCS'}

            You receiving this email means that you have agreed to provide authority to Meraviglia Consulting Services (MCS), to complete state business establishment forms and acquire a Federal EIN number on your behalf.
        `;

        let vinceEmailParams = {
            to_name: this.state.contactInfo.firstName,
            message: messageToVince,
        }

        let customerEmailParams = {
            to_name: this.state.contactInfo.firstName,
            to_email: this.state.contactInfo.email,
            message: messageToCustomer,
        }

        emailjs.send('service_me743hm', "template_k20j57v", vinceEmailParams, { publicKey: 'zqOT2Ml72GX2sCfpG' }).then(
            () => {
                setTimeout(() => {
                    emailjs.send('service_me743hm', "template_7d5115o", customerEmailParams, { publicKey: 'zqOT2Ml72GX2sCfpG' }).then(
                        (response) => {
                            console.log('Response: ', response.text);
                            window.location.reload();
                            this.setState({ isSubmitDisabled: false });
                        }
                    );
                }, "1000")
            },
            (error) => {
                console.log('Failed', error);
            }
        );
    }

    render() {
        return (
            <form className='justify-center flex py-8' onSubmit={this.handleSubmit.bind(this)}>
                <div className="w-3/4 sm:w-1/2 space-y-12">
                    <div className="border-b-2 border-violet-500 pb-12">
                        <div className="flex justify-center">
                            <img src="MCSLogo.png" className="w-1/2 mb-8" alt="" />
                        </div>
                        <p className="mt-1 text-sm leading-6 text-white">
                            MCS will obtain the forms necessary for state business formation documents and federal EIN / Tax ID number.
                        </p>   
                        <p className="mt-1 text-sm leading-6 text-white">
                            Provide the information in the 5 sections of form below which takes approximately 10 to 15 minutes to complete. MCS will contact you in the event that we have questions and/or need further clarification.  The filing is initiated within 2 business days of completion of this form (assuming no additional information is required).
                        </p>
                        <p className="mt-1 text-sm leading-6 text-white">
                            Fee is $100 + state filing fees (varies by state).
                        </p>
                        <p className="mt-1 text-sm leading-6 text-white indent-8">
                            What can you expect from MCS?
                        </p>
                        <ul className="mt-1 text-sm leading-6 text-white list-disc list-inside indent-8">
                            <li>
                                Secure and privacy-protected data
                            </li>
                            <li>
                                Email confirmation of successful completion of this form.
                            </li>
                            <li>
                                State business formation documents and federal EIN / Tax ID number.  Typically completed in 3 to 5 weeks (varies by state).
                            </li>
                        </ul>
                        <p className="mt-1 text-sm leading-6 text-white">
                            Any Questions?
                        </p>
                        <p className="mt-1 text-sm leading-6 text-white">
                            Contact us: inc@MCSbizsetup.com or 855 627 6271 x4
                        </p>
                    </div>

                    <div className="border-b-2 border-violet-500 pb-12">
                        <h2 className="text-base font-semibold leading-7 text-white">Disclaimer</h2>
                        <p className="mt-1 text-sm leading-6 text-white">
                            We may receive referral fees at no additional cost to you through our partnerships with service 
                            providers. All information provided in this webform will be solely used for the purpose of obtaining 
                            business formation documents and related service(s). MCS does not share information with any party outside 
                            of fulfilling business formation services.  
                        </p>
                    </div>

                    <div className="border-b-2 border-violet-500 pb-12">
                        <h2 className="mb-10 text-xl font-semibold leading-7 text-white">Section 1: Contact Info</h2>
                        <h3 className="mb-4 text-base font-semibold leading-7 text-white">Contact</h3>
                        <div className="mb-8 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 border-b-2 border-violet-500 pb-12">
                            <div className="sm:col-span-3">
                                <label htmlFor="contact-first-name" className="block text-sm font-medium leading-6 text-white">
                                    First name
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        value={this.state.contactInfo.firstName}
                                        onChange={this.handleContactChange.bind(this)}
                                        name="contact-first-name"
                                        id="contact-first-name"
                                        autoComplete="given-name"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="contact-first-name" className="block text-sm font-medium leading-6 text-white">
                                    Middle name (optional)
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        value={this.state.contactInfo.middleName}
                                        onChange={this.handleContactChange.bind(this)}
                                        name="contact-middle-name"
                                        id="contact-middle-name"
                                        autoComplete="middle-name"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-white">
                                    Last name
                                </label>
                                <div className="mt-2">
                                    <input
                                        type="text"
                                        value={this.state.contactInfo.lastName}
                                        onChange={this.handleContactChange.bind(this)}
                                        name="contact-last-name"
                                        id="contact-last-name"
                                        autoComplete="family-name"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="suffix" className="block text-sm font-medium leading-6 text-white">
                                    Suffix
                                </label>
                                <div className="mt-2">
                                    <select
                                        value={this.state.contactInfo.suffix}
                                        onChange={this.handleContactChange.bind(this)}
                                        id="contact-suffix"
                                        name="contact-suffix"
                                        autoComplete="suffix"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                                    >
                                        <option></option>
                                        <option>Jr.</option>
                                        <option>Sr.</option>
                                        <option>II</option>
                                        <option>III</option>
                                        <option>IV</option>
                                        <option>M.D.</option>
                                        <option>Ph.D.</option>
                                    </select>
                                </div>
                            </div>


                            <div className="sm:col-span-4">
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-white">
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        value={this.state.contactInfo.email}
                                        onChange={this.handleContactChange.bind(this)}
                                        id="contact-email"
                                        name="contact-email"
                                        type="email"
                                        autoComplete="email"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                        required
                                    />
                                </div>
                            </div>

                            <div class="sm:col-span-4">
                                <label htmlFor="phone" className="block text-sm font-medium leading-6 text-white">
                                    Phone Number
                                </label>
                                <div className="mt-2">
                                    <input 
                                        type="text" 
                                        value={this.state.contactInfo.phone}
                                        onChange={this.handleContactChange.bind(this)}
                                        id="contact-phone-number"
                                        name="contact-phone-number"
                                        aria-describedby="helper-text-explanation"
                                        class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                        placeholder="(123) 456-7890"
                                        required
                                    />
                                </div>
                            </div>
                
                            <div className="sm:col-span-3">
                                <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
                                    Country
                                </label>
                                <div className="mt-2">
                                    <select
                                        value={this.state.contactInfo.country}
                                        onChange={this.handleContactChange.bind(this)}
                                        id="contact-country"
                                        name="contact-country"
                                        autoComplete="country-name"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                                    >
                                        <option>United States</option>
                                    </select>
                                </div>
                            </div>
                
                            <div className="col-span-full">
                                <label htmlFor="contact-address" className="block text-sm font-medium leading-6 text-white">
                                    Street address
                                </label>
                                <div className="mt-2">
                                    <input
                                        value={this.state.contactInfo.address}
                                        onChange={this.handleContactChange.bind(this)}
                                        type="text"
                                        name="contact-address"
                                        id="contact-address"
                                        autoComplete="address"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="contact-address-2" className="block text-sm font-medium leading-6 text-white">
                                    Street address 2 (optional)
                                </label>
                                <div className="mt-2">
                                    <input
                                        value={this.state.contactInfo.address2}
                                        onChange={this.handleContactChange.bind(this)}
                                        type="text"
                                        name="contact-address-2"
                                        id="contact-address-2"
                                        autoComplete="address"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2 sm:col-start-1">
                                <label htmlFor="contact-city" className="block text-sm font-medium leading-6 text-white">
                                    City
                                </label>
                                <div className="mt-2">
                                    <input
                                        value={this.state.contactInfo.city}
                                        onChange={this.handleContactChange.bind(this)}
                                        type="text"
                                        name="contact-city"
                                        id="contact-city"
                                        autoComplete="city"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                
                            <div className="sm:col-span-2">
                                <label htmlFor="region" className="block text-sm font-medium leading-6 text-white">
                                    State
                                </label>
                                <div className="mt-2">
                                    <input
                                    value={this.state.contactInfo.state}
                                    onChange={this.handleContactChange.bind(this)}
                                    type="text"
                                    name="contact-state"
                                    id="contact-state"
                                    autoComplete="address-level1"
                                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                    required
                                    />
                                </div>
                            </div>
                
                            <div className="sm:col-span-2">
                                <label htmlFor="zip" className="block text-sm font-medium leading-6 text-white">
                                    ZIP
                                </label>
                                <div className="mt-2">
                                    <input
                                        value={this.state.contactInfo.zip}
                                        onChange={this.handleContactChange.bind(this)}
                                        type="text"
                                        name="contact-zip"
                                        id="contact-zip"
                                        autoComplete="zip"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        
                        <h3 className="mb-8 text-xl font-semibold leading-7 text-white">Section 2: Owners</h3>
                        { this.state.memberInfo.map((member, i) => (
                            <div className="mb-4 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 border-b-2 border-violet-500 pb-12">
                                <div className="sm:col-span-6 flex justify-between">
                                    <h3 className="text-base font-semibold leading-7 text-white">Owner {i + 1}</h3>
                                    { i !== 0 &&
                                        <button 
                                            type="button"
                                            class="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                            onClick={(e) => this.removeMember(i)}
                                        >
                                            <span class="sr-only">Close menu</span>
                                            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    }
                                </div>
                                <div className="sm:col-span-3">
                                    <label htmlFor={`member-first-name-${i}`} className="block text-sm font-medium leading-6 text-white">
                                        First name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            value={member.firstName}
                                            onChange={(e) => this.handleMemberChange(e, i)}
                                            name={`member-first-name-${i}`}
                                            id={`member-first-name-${i}`}
                                            autoComplete="given-name"
                                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor={`member-middle-name-${i}`} className="block text-sm font-medium leading-6 text-white">
                                        Middle name (optional)
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            value={member.middleName}
                                            onChange={(e) => this.handleMemberChange(e, i)}
                                            name={`member-middle-name-${i}`}
                                            id={`member-middle-name-${i}`}
                                            autoComplete="given-name"
                                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor={`member-last-name-${i}`} className="block text-sm font-medium leading-6 text-white">
                                        Last name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            value={member.lastName}
                                            onChange={(e) => this.handleMemberChange(e, i)}
                                            name={`member-last-name-${i}`}
                                            id={`member-last-name-${i}`}
                                            autoComplete="given-name"
                                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="suffix" className="block text-sm font-medium leading-6 text-white">
                                        Suffix
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            value={member.suffix}
                                            onChange={(e) => this.handleMemberChange(e, i)}
                                            id={`member-suffix-${i}`}
                                            name={`member-suffix-${i}`}
                                            autoComplete="suffix"
                                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                                        >
                                            <option></option>
                                            <option>Jr.</option>
                                            <option>Sr.</option>
                                            <option>II</option>
                                            <option>III</option>
                                            <option>IV</option>
                                            <option>M.D.</option>
                                            <option>Ph.D.</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor={`member-email-${i}`} className="block text-sm font-medium leading-6 text-white">
                                        Email Address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="email"
                                            value={member.email}
                                            onChange={(e) => this.handleMemberChange(e, i)}
                                            name={`member-email-${i}`}
                                            id={`member-email-${i}`}
                                            autoComplete="given-name"
                                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                            required
                                        />
                                    </div>
                                </div>

                                <div class="sm:col-span-4">
                                    <label htmlFor="phone" className="block text-sm font-medium leading-6 text-white">
                                        Phone Number
                                    </label>
                                    <div className="mt-2">
                                        <input 
                                            type="text" 
                                            value={member.phone}
                                            onChange={(e) => this.handleMemberChange(e, i)}
                                            id={`member-phone-number-${i}`}
                                            name={`member-phone-number-${i}`}
                                            aria-describedby="helper-text-explanation"
                                            class="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                            placeholder="123-456-7890"
                                            required
                                        />
                                    </div>
                                </div>
                    
                                <div className="sm:col-span-3">
                                    <label htmlFor="country" className="block text-sm font-medium leading-6 text-white">
                                        Country
                                    </label>
                                    <div className="mt-2">
                                        <select
                                            value={member.country}
                                            onChange={(e) => this.handleMemberChange(e, i)}
                                            id={`member-country-${i}`}
                                            name={`member-country-${i}`}
                                            autoComplete="country-name"
                                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                                        >
                                            <option>United States</option>
                                        </select>
                                    </div>
                                </div>
                    
                                <div className="col-span-full">
                                    <label htmlFor="member-address" className="block text-sm font-medium leading-6 text-white">
                                        Street address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            value={member.address}
                                            onChange={(e) => this.handleMemberChange(e, i)}
                                            type="text"
                                            name={`member-address-${i}`}
                                            id={`member-address-${i}`}
                                            autoComplete="address"
                                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="member-address-2" className="block text-sm font-medium leading-6 text-white">
                                        Street address 2 (optional)
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            value={member.address2}
                                            onChange={(e) => this.handleMemberChange(e, i)}
                                            type="text"
                                            name={`member-address-2-${i}`}
                                            id={`member-address-2-${i}`}
                                            autoComplete="address"
                                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-2 sm:col-start-1">
                                    <label htmlFor="member-city" className="block text-sm font-medium leading-6 text-white">
                                        City
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            value={member.city}
                                            onChange={(e) => this.handleMemberChange(e, i)}
                                            type="text"
                                            name={`member-city-${i}`}
                                            id={`member-city-${i}`}
                                            autoComplete="city"
                                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                    
                                <div className="sm:col-span-2">
                                    <label htmlFor="member-state" className="block text-sm font-medium leading-6 text-white">
                                        State
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            value={member.state}
                                            onChange={(e) => this.handleMemberChange(e, i)}
                                            type="text"
                                            name={`member-state-${i}`}
                                            id={`member-state-${i}`}
                                            autoComplete="address-level1"
                                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                            required
                                        />
                                    </div>
                                </div>
                    
                                <div className="sm:col-span-2">
                                    <label htmlFor="zip" className="block text-sm font-medium leading-6 text-white">
                                        ZIP
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            value={member.zip}
                                            onChange={(e) => this.handleMemberChange(e, i)}
                                            type="text"
                                            name={`member-zip-${i}`}
                                            id={`member-zip-${i}`}
                                            autoComplete="zip"
                                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <div>
                                        <label htmlFor="ownership-percentage" className="block text-sm font-medium leading-6 text-white">
                                            % Ownership (Must total exactly 100 across all owners)
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                value={member.ownershipPercentage}
                                                onChange={(e) => this.handleMemberChange(e, i)}
                                                type="number"
                                                name={`member-ownership-percentage-${i}`}
                                                id={`member-ownership-percentage-${i}`}
                                                autoComplete="ownership-percentage"
                                                className="mb-8 block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                                required
                                            />
                                        </div>
                                    </div>
                                    { i === this.state.memberInfo.length - 1 && i < 2 &&
                                        <button 
                                            class="whitespace-nowrap rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                                            onClick={this.addMember.bind(this)}
                                        >
                                            Add Owner
                                        </button>
                                    }
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="ssn" className="block text-sm font-medium leading-6 text-white">
                                        SSN
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            value={member.ssn}
                                            onChange={(e) => this.handleMemberChange(e, i)}
                                            type="password"
                                            name={`member-ssn-${i}`}
                                            id={`member-ssn-${i}`}
                                            autoComplete="ssn"
                                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                            required
                                        />
                                    </div>
                                </div>

                            </div>
                        ))}


                        <h3 className="mb-4 text-xl font-semibold leading-7 text-white">Section 3: Company Name and Situs</h3>
                        <div className=" grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="col-span-full">
                                <label htmlFor="company-name-1" className="block text-sm font-medium leading-6 text-white">
                                    Option 1
                                </label>
                                <div className="mt-2">
                                    <input
                                        value={this.state.companyName1}
                                        onChange={this.handleCompanyNameChange.bind(this)}
                                        type="text"
                                        name="company-name-1"
                                        id="company-name-1"
                                        autoComplete="company-name-1"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="company-name-2" className="block text-sm font-medium leading-6 text-white">
                                    Option 2
                                </label>
                                <div className="mt-2">
                                    <input
                                        value={this.state.companyName2}
                                        onChange={this.handleCompanyNameChange.bind(this)}
                                        type="text"
                                        name="company-name-2"
                                        id="company-name-2"
                                        autoComplete="address"
                                        placeholder="If first choice company name is taken, provide backup company name"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="company-name-3" className="block text-sm font-medium leading-6 text-white">
                                    Option 3
                                </label>
                                <div className="mt-2">
                                    <input
                                        value={this.state.companyName3}
                                        onChange={this.handleCompanyNameChange.bind(this)}
                                        type="text"
                                        name="company-name-3"
                                        id="company-name-3"
                                        autoComplete="address"
                                        placeholder="If second choice company name is taken, provide backup company name"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="company-address" className="block text-sm font-medium leading-6 text-white">
                                    Street address
                                </label>
                                <div className="mt-2">
                                    <input
                                        value={this.state.companyInfo.address}
                                        onChange={this.handleCompanyChange.bind(this)}
                                        type="text"
                                        name="company-address"
                                        id="company-address"
                                        autoComplete="address"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="company-address-2" className="block text-sm font-medium leading-6 text-white">
                                    Street address 2 (optional)
                                </label>
                                <div className="mt-2">
                                    <input
                                        value={this.state.companyInfo.address2}
                                        onChange={this.handleCompanyChange.bind(this)}
                                        type="text"
                                        name="company-address-2"
                                        id="company-address-2"
                                        autoComplete="address"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-2 sm:col-start-1">
                                <label htmlFor="company-city" className="block text-sm font-medium leading-6 text-white">
                                    City
                                </label>
                                <div className="mt-2">
                                    <input
                                        value={this.state.companyInfo.city}
                                        onChange={this.handleCompanyChange.bind(this)}
                                        type="text"
                                        name="company-city"
                                        id="company-city"
                                        autoComplete="city"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>
                
                            <div className="sm:col-span-2">
                                <label htmlFor="region" className="block text-sm font-medium leading-6 text-white">
                                    State
                                </label>
                                <div className="mt-2">
                                    <input
                                    value={this.state.companyInfo.state}
                                    onChange={this.handleCompanyChange.bind(this)}
                                    type="text"
                                    name="company-state"
                                    id="company-state"
                                    autoComplete="address-level1"
                                    className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                    required
                                    />
                                </div>
                            </div>
                
                            <div className="sm:col-span-2">
                                <label htmlFor="zip" className="block text-sm font-medium leading-6 text-white">
                                    ZIP
                                </label>
                                <div className="mt-2">
                                    <input
                                        value={this.state.companyInfo.zip}
                                        onChange={this.handleCompanyChange.bind(this)}
                                        type="text"
                                        name="company-zip"
                                        id="company-zip"
                                        autoComplete="zip"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="border-b-2 border-violet-500 pb-12">
                        <h2 className="mb-10 text-xl font-semibold leading-7 text-white">Section 4: Entity Details</h2>
                        <div className="mb-8 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="entity-type" className="block text-sm font-medium leading-6 text-white">
                                    Entity Type
                                </label>
                                <div className="mt-2">
                                    <select
                                        value={this.state.entityType}
                                        onChange={this.handleEntityChange.bind(this)}
                                        id="entity-type"
                                        name="entity-type"
                                        autoComplete="entity-type"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                                    >
                                        <option>LLC</option>
                                        <option>C Corp</option>
                                        <option>S Corp</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        
                        <div className="mb-8 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="principle-activity" className="block text-sm font-medium leading-6 text-white">
                                    Principle Activity
                                </label>
                                <div className="mt-2">
                                    <select
                                        value={this.state.principleActivity}
                                        onChange={this.handleEntityChange.bind(this)}
                                        id="principle-activity"
                                        name="principle-activity"
                                        autoComplete="principle-activity"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                                    >
                                        <option>Transportation & Warehousing</option>
                                        <option>Construction</option>
                                        <option>Real Estate</option>
                                        <option>Rent & Leasing</option>
                                        <option>Manufacturing</option>
                                        <option>Finance & Insurance</option>
                                        <option>Healthcare & Social Assistance</option>
                                        <option>Accommodations and Food Service</option>
                                        <option>Wholesaler - Agent / Broker</option>
                                        <option>Retailer</option>
                                        <option>Other</option>
                                    </select>
                                </div>
                            </div>
                            { this.state.principleActivity === 'Other' &&
                                <div className="sm:col-span-2">
                                    <label htmlFor="other-principle-activity" className="block text-sm font-medium leading-6 text-white">
                                        Other:
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            value={this.state.otherPrincipleActivity}
                                            onChange={this.handleEntityChange.bind(this)}
                                            type="text"
                                            name="other-principle-activity"
                                            id="other-principle-activity"
                                            autoComplete="other-principle-activity"
                                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                            required
                                        />
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="mb-8 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="listed" className="block text-sm font-medium leading-6 text-white">
                                    Some states require that the initial LLC member names and addresses be listed on the formation documents.
                                    If provided the option by the state you selected to form your entity, do you prefer name(s) be included or excluded?				
                                </label>
                                <div className="mt-2">
                                    <select
                                        value={this.state.listed}
                                        onChange={this.handleEntityChange.bind(this)}
                                        id="listed"
                                        name="listed"
                                        autoComplete="listed"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                                    >
                                        <option>Included</option>
                                        <option>Excluded</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="border-b-2 border-violet-500 pb-12">
                        <h2 className="mb-10 text-xl font-semibold leading-7 text-white">Section 5: Registered Agent</h2>
                        <div className="mb-8 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="entity-type" className="block text-sm font-medium leading-6 text-white">
                                    Will we be handling your registered agent?
                                </label>
                                <div className="mt-2">
                                    <select
                                        onChange={this.handleRequireAgentChange.bind(this)}
                                        id="entity-type"
                                        name="entity-type"
                                        autoComplete="entity-type"
                                        className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                                    >
                                        <option>No, I will have my own Registered Agent. Name and address provided below.</option>
                                        <option>Yes, Fee is $0 for first year after incorporation and $119 every year thereafter. Cancel anytime.</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        { this.state.requireRegisteredAgent === false &&
                            <div>
                                <div className="mb-8 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                    <div className="sm:col-span-5">
                                        <label htmlFor="agent-company-name" className="block text-sm font-medium leading-6 text-white">
                                            Company Name (If Applicable)
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                value={this.state.agentInfo.companyName}
                                                onChange={this.handleAgentChange.bind(this)}
                                                name="agent-company-name"
                                                id="agent-company-name"
                                                autoComplete="given-name"
                                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="agent-first-name" className="block text-sm font-medium leading-6 text-white">
                                            First name
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                value={this.state.agentInfo.firstName}
                                                onChange={this.handleAgentChange.bind(this)}
                                                name="agent-first-name"
                                                id="agent-first-name"
                                                autoComplete="given-name"
                                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="agent-middle-name" className="block text-sm font-medium leading-6 text-white">
                                            Middle name (optional)
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                value={this.state.agentInfo.middleName}
                                                onChange={this.handleAgentChange.bind(this)}
                                                name="agent-middle-name"
                                                id="agent-middle-name"
                                                autoComplete="middle-name"
                                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-white">
                                            Last name
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                type="text"
                                                value={this.state.agentInfo.lastName}
                                                onChange={this.handleAgentChange.bind(this)}
                                                name="agent-last-name"
                                                id="agent-last-name"
                                                autoComplete="family-name"
                                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-3">
                                        <label htmlFor="suffix" className="block text-sm font-medium leading-6 text-white">
                                            Suffix
                                        </label>
                                        <div className="mt-2">
                                            <select
                                                value={this.state.agentInfo.suffix}
                                                onChange={this.handleAgentChange.bind(this)}
                                                id="agent-suffix"
                                                name="agent-suffix"
                                                autoComplete="suffix"
                                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6 [&_*]:text-black"
                                            >
                                                <option></option>
                                                <option>Jr.</option>
                                                <option>Sr.</option>
                                                <option>II</option>
                                                <option>III</option>
                                                <option>IV</option>
                                                <option>M.D.</option>
                                                <option>Ph.D.</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-span-full">
                                        <label htmlFor="agent-address" className="block text-sm font-medium leading-6 text-white">
                                            Street address
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                value={this.state.agentInfo.address}
                                                onChange={this.handleAgentChange.bind(this)}
                                                type="text"
                                                name="agent-address"
                                                id="agent-address"
                                                autoComplete="address"
                                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="col-span-full">
                                        <label htmlFor="agent-address-2" className="block text-sm font-medium leading-6 text-white">
                                            Street address 2 (optional)
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                value={this.state.agentInfo.address2}
                                                onChange={this.handleAgentChange.bind(this)}
                                                type="text"
                                                name="agent-address-2"
                                                id="agent-address-2"
                                                autoComplete="address"
                                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>

                                    <div className="sm:col-span-2 sm:col-start-1">
                                        <label htmlFor="agent-city" className="block text-sm font-medium leading-6 text-white">
                                            City
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                value={this.state.agentInfo.city}
                                                onChange={this.handleAgentChange.bind(this)}
                                                type="text"
                                                name="agent-city"
                                                id="agent-city"
                                                autoComplete="city"
                                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                        
                                    <div className="sm:col-span-2">
                                        <label htmlFor="region" className="block text-sm font-medium leading-6 text-white">
                                            State
                                        </label>
                                        <div className="mt-2">
                                            <input
                                            value={this.state.agentInfo.state}
                                            onChange={this.handleAgentChange.bind(this)}
                                            type="text"
                                            name="agent-state"
                                            id="agent-state"
                                            autoComplete="address-level1"
                                            className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                            required
                                            />
                                        </div>
                                    </div>
                        
                                    <div className="sm:col-span-2">
                                        <label htmlFor="zip" className="block text-sm font-medium leading-6 text-white">
                                            ZIP
                                        </label>
                                        <div className="mt-2">
                                            <input
                                                value={this.state.agentInfo.zip}
                                                onChange={this.handleAgentChange.bind(this)}
                                                type="text"
                                                name="agent-zip"
                                                id="agent-zip"
                                                autoComplete="zip"
                                                className="block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
            
                    <div className="pb-4">
                        <div className="mt-10 space-y-10">
                            <fieldset>
                            <legend className="text-sm font-semibold leading-6 text-white">Acknowledge</legend>
                            <div className="mt-6 space-y-6">
                                <div className="relative flex gap-x-3">
                                    <div className="flex h-6 items-center">
                                        <input
                                        id="form-acknowledgement"
                                        name="form-acknowledgement"
                                        defaultChecked={this.state.formAcknowledged}
                                        onClick={this.handleFormAcknowledgement.bind(this)}
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                                        />
                                    </div>
                                    <div className="text-sm leading-6">
                                        <p className="text-white">
                                            I agree to provide authority to Meraviglia Consulting Services (MCS), 
                                            to complete state business establishment forms and acquire a Federal EIN number on my behalf.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-6 space-y-6">
                                <div className="relative flex gap-x-3">
                                    <div className="flex h-6 items-center">
                                        <input
                                        id="related-offers"
                                        name="related-offers"
                                        defaultChecked={this.state.relatedOffers}
                                        onClick={this.handleRelatedOffers.bind(this)}
                                        type="checkbox"
                                        className="h-4 w-4 rounded border-white/10 bg-white/5 text-indigo-600 focus:ring-indigo-600 focus:ring-offset-gray-900"
                                        />
                                    </div>
                                    <div className="text-sm leading-6">
                                        <p className="text-white">
                                            Optional: Allow MCS to send me related offers
                                        </p>
                                    </div>
                                </div>
                            </div>
                            </fieldset>
                        </div>
                    </div>
                    <div>
                        <p className="text-red-500">
                            If submit button is disabled, make sure that all necessary data is provided and that ownership percentages equal exactly 100
                        </p>
                    </div>
                    <div className="mt-6 flex items-center justify-end gap-x-6">
                        <button
                            type="submit"
                            className={`rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${this.state.isSubmitDisabled ? "text-gray-400 bg-gray-600" : "text-white bg-indigo-500 hover:bg-indigo-400 focus-visible:outline-indigo-500"}`}
                            disabled={this.state.isSubmitDisabled}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}

export default CorporateForm;