import { Component } from 'react';
import './home.css';

class Home extends Component {
    render() {
        return (
            <div className='justify-center flex py-8 page-full-height'>
                <div className="w-3/4 sm:w-1/2 space-y-12">
                    <div className="pb-12">
                        <div className="flex justify-center">
                            <img src="MCSLogo.png" className="w-1/2 mb-8" alt="" />
                        </div>
                        <h2 className="mb-16 text-7xl font-semibold leading-7 text-white flex justify-center">Business Setup</h2>
                        <h2 className="mb-16 text-7xl font-semibold leading-7 text-white flex justify-center">Made Easy</h2>
                        <p className="mb-2 text-2xl font-semibold leading-7 text-white flex justify-center">15 minutes to complete a webform</p>
                        <p className="mb-2 text-2xl font-semibold leading-7 text-white flex justify-center">+</p>
                        <p className="mb-2 text-2xl font-semibold leading-7 text-white flex justify-center">$100 + State Fees (one time fee)</p>
                        <p className="mb-2 text-2xl font-semibold leading-7 text-white flex justify-center">=</p>
                        <p className="mb-2 text-2xl font-semibold leading-7 text-white flex justify-center">Business formed in approximately 30 days</p>
                        <p className="mb-10 text-2xl font-semibold leading-7 text-white flex justify-center">State filing and Federal EIN</p>
                        <p className="mb-2 text-lg font-semibold leading-7 text-white flex justify-center">inc@mcsbizsetup.com</p>
                        <p className="mb-2 text-lg font-semibold leading-7 text-white flex justify-center">855 627 6271 x701</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;